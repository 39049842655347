import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
  return (
    <Layout>
      <div className="jumbotron">
        <Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/news" />
        <h1>Newsletter</h1>
        <p className="jumbotron-text">
          Wenn Sie über die Neuerscheinung von OpenBooks inmfomiert werden möchten, können Sie mir über <a className="orange1" href="https://kaiser-ulrich.de/formular">dieses Formular</a> eine Mailadresse zukommen lassen, damit ich Sie über Neuerscheinungen informieren kann. Selbstverständlich wird Ihre Mailadrsse von mir nicht weitergegeben und auf Wunsch selbstverständlich auch wieder gelöscht (-> <Link className="orange1" to="/impressum">Datenschutz</Link>).
        </p>
      </div>

      <div className="gridBox">
        <div className="gridFraction displayNonInSmallDevices">PDF</div>
        <div className="gridFraction displayNonInSmallDevices">Kurzbeschreibung</div>
        <div className="gridFraction">
          <Link to="/publicfiles/018_2020-09-21.pdf"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link className="inline" to="/publicfiles/019_2021-04-18.pdf">Newsletter Nr. 19</Link> zur Veröffentlichung des OpenBooks <Link to="/oer-walzer-von-brahms-arrangiert-fuer-zwei-violinen">J. Brahms. Walzer aus Op. 39 arrangiert für 2 Violinen</Link> (=OpenBook 17/1), Karlsfeld 2021.
          </div>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/017_2019-03-04.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>

          </div>
        </div>
        <div className="gridFraction">
          <div>
            <Link className="inline" to="/publicfiles/018_2020-09-21.pdf">Newsletter Nr. 18</Link> zur Veröffentlichung des OpenBooks <Link to="/filmmusik">Filmmusik</Link> (=OpenBook 15), Karlsfeld 2020.
          </div>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/017_2019-03-04.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
          </div>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/017_2019-03-04.pdf" target="_blank">Newsletter Nr. 17</Link> zur Veröffentlichung des Musikhefts <Link to="/formenlehre">Formenlehre der Musik</Link> (=OpenBook 13), Karlsfeld 2019.
          </div>
        </div>
        <div className="gridFraction">
           <Link to="/publicfiles/016_2017-10-28.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/016_2017-10-28.pdf" target="_blank">Newsletter Nr. 16</Link> zur Veröffentlichung der Musikhefte <Link to="/carmensuite">George Bizet. Carmen zu einer Suite arrangiert von Ulrich Kaiser</Link> (=OpenBook 18/2), Karlsfeld 2017 und <Link to="/happyforchoir">Kanon und 8 Variationen über Happy Birthday To You für vierstimmig gemischten Chor</Link> (=OpenBook 18/2).
          </div>
        </div>
        <div className="gridFraction">
            <Link to="/publicfiles/015_2017-02-18.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/015_2017-02-18.pdf" target="_blank">Newsletter Nr. 15</Link> zur Veröffentlichung des Musikheftes <Link to="/brahms5">Johannes Brahms. Ungarischer Tanz Nr. 5 arrangiert und mit einer Einleitung versehen von Ulrich Kaiser</Link> (=OpenBook 18/1), Karlsfeld 2017
          </div>
        </div>
        <div className="gridFraction">
            <Link to="/publicfiles/014_2017-01-01.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/014_2017-01-01.pdf" target="_blank">Newsletter Nr. 14</Link> zur Veröffentlichung des Musikheftes <Link to="/happyfortwo">Thema und 12 Variationen über ›Happy Birthday To You‹ für zwei beliebige Melodieinstrumente</Link> (=OpenBook 14/1), Karlsfeld 2017 (am 01.01.2017)
          </div>
        </div>
        <div className="gridFraction">
            <Link to="/publicfiles/013_2016-10-11.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/013_2016-10-11.pdf" target="_blank">Newsletter Nr. 13</Link> zur Veröffentlichung des Musikheftes <Link to="/transponierendeinstrumente">Orchesterinstrumente. Transponierende Instrumente</Link> (=OpenBook 16), Karlsfeld 2016 (am 11.10.2016)
          </div>
        </div>
        <div className="gridFraction">
            <Link to="/publicfiles/012_2016-07-16.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/012_2016-07-16.pdf" target="_blank">Newsletter Nr. 12</Link> zur Veröffentlichung des Musikheftes <Link to="/harmonielehre">Harmonielehre #reloaded</Link> (=OpenBook 12), Karlsfeld 2016 (am 15.07.2016)
          </div>
        </div>
        <div className="gridFraction">
         <Link to="/publicfiles/011_2016-02-26.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/011_2016-02-26.pdf" target="_blank">Newsletter Nr. 11</Link> zur Veröffentlichung des Musikheftes <Link to="/bandimpulse4">Band-Impulse. Heft 4: Gehörbildung und Musiklehre. Übungen in Dur</Link> (=OpenBook 9 / Heft4), Karlsfeld 2016 (am 26.02.2016)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/010_2015-12-13.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/010_2015-12-13.pdf" target="_blank">Newsletter Nr. 10</Link> zur Veröffentlichung des Musikheftes <Link to="/inventionen">Inventionen plus One. Kammermusik mit Johann Sebastian Bach</Link> (=OpenBook 11), Karlsfeld 2015 (am 14.12.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/009_2015-12-07.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/009_2015-12-07.pdf" target="_blank">Newsletter Nr. 9</Link> zur Veröffentlichung des Musikheftes <Link to="/bandImpulse3">Band-Impulse. Heft 3: Pattern und Styles 1 (Pop und Rap)</Link> (=OpenBook 9 / Heft3), Karlsfeld 2015 (am 07.12.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/008_2015-09-21.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/008_2015-09-21.pdf" target="_blank">Newsletter Nr. 8</Link> zur Veröffentlichung des Musikheftes <Link to="/bandImpulse2">Band-Impulse. Heft 2: Elise In Black</Link> (=OpenBook 9 / Heft2), Karlsfeld 2015 (am 21.09.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/007_2015-07-12.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/007_2015-07-12.pdf" target="_blank">Newsletter Nr. 7</Link> zum Relaunch der Website sowie zur Veröffentlichung des Musikheftes <Link to="/Kinderhauslied">Das Kinderhauslied.</Link> (=OpenBook 10), Karlsfeld 2015 (am 12.07.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/006_2015-06-11.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/006_2015-06-11.pdf" target="_blank">Newsletter Nr. 6</Link> zur Veröffentlichung des Musikheftes <Link to="/bandImpulse1">Band-Impulse. Heft 1: That Image.</Link> (=OpenBook 9 / Heft1), Karlsfeld 2015  (am 11.06.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/005_2015-04-05.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/005_2015-04-05.pdf" target="_blank">Newsletter Nr. 5</Link> zur Veröffentlichung des Heftes <Link to="/harmonieundform">Harmonielehre. Harmonie und Form für Kompositionen in Dur</Link> (=OpenBook 8), Karlsfeld 2015 (am 05.04.2015)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/003_2014-01-12.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/004_2014-09-29.pdf" target="_blank">Newsletter Nr. 4</Link> zur Veröffentlichung des Heftes <Link to="/kostenlosesoftware">Kostenlose Software für den Musikunterricht.</Link> (=OpenBook 7), Arbeitsheft, inkl. Materialien, Software und Videos, Karlsfeld 2014 (am 29.09.2014)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/003_2014-01-12.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/003_2014-03-10.pdf" target="_blank">Newsletter Nr. 3</Link> zur Veröffentlichung des Kommentarheftes zu <Link to="/beethoven5">Ludwig van Beethoven. Die 5. Sinfonie</Link> (= OpenBook 6) sowie Spielen (Million-Game und Pairs-Kartenspiele) und des Themenbogens <Link to="/papers">Klang und Akkord</Link> (am 10.03.2014)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/002_2014-01-12.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/002_2014-01-12.pdf" target="_blank">Newsletter Nr. 2</Link> zur Veröffentlichung von <Link to="/beethoven5">Ludwig van Beethoven. Die 5. Sinfonie</Link> (= OpenBook 6), Karlsfeld 2014 (am 12.01.2014)
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/publicfiles/001_2013-09-16.pdf" target="_blank"><img src="/images/pdficon_large.png" /></Link>
        </div>
        <div className="gridFraction">
          <div>
            <Link to="/publicfiles/001_2013-09-16.pdf" target="_blank">Newsletter Nr. 1</Link> zur Veröffentlichung von <Link to="/lamento">Lamentobass. Musik aus vier Jahrhunderten</Link> (= OpenBook 5), Karlsfeld 2013 (am 17.09.2013)
          </div>
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Newsletter</title>
        <meta name="description" content="Hier finden Sie Informationen zu den OpenBooks (OER) von Ulrich Kaiser in Form von Newslettern." />
        <meta name="keywords" content="News, Newsletter, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}
